import React from "react";
import { components } from "react-select";
import Select from "react-select/lib/Creatable";
import { ApolloProvider } from "react-apollo";
import DeveloperAPI from "../services/DeveloperAPIClient";
import { compose, graphql } from "react-apollo";
import { AllTokensQuery, CreateToken } from "../services/DeveloperQueries";
import moment from "moment";
import { Button } from "@blueprintjs/core";

import { colors } from "react-select/lib/theme";

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" })
};

class TokenPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedOption:
        props.query.allTokens &&
        props.query.allTokens.edges &&
        props.query.allTokens.edges.length > 0 &&
        this.getOption(props.query.allTokens.edges[0].node),
      isCreating: false,
      creationError: null
    };

    if (this.state.selectedOption && props.onSelect) {
      props.onSelect(this.state.selectedOption);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      !this.state.selectedOption &&
      !this.props.query.allTokens &&
      nextProps.query.allTokens &&
        nextProps.query.allTokens.edges &&
        nextProps.query.allTokens.edges.length > 0
    ) {
      const opt = this.getOption(nextProps.query.allTokens.edges[0].node);
      this.setState({
        selectedOption: opt
      });

      if (nextProps.onSelect) {
        nextProps.onSelect(opt.value);
      }
    }
  }

  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  onSelectChange = selectedOption => {
    this.toggleOpen();
    this.setState({ selectedOption });
    if (this.props.onSelect) {
      this.props.onSelect(selectedOption && selectedOption.value);
    }
  };

  create = () => {
    this.setState({
      isCreating: true,
      isOpen: false,
      creationError: null
    });
    // try {
    this.props.createToken().then(r => {
      this.props.query.refetch().then(() => {
        this.setState({
          selectedOption: this.getOption(r.data.createToken),
          isCreating: false
        });
      });
    });
  };

  getOption = token => ({
    value: token.key,
    label: `${token.key.slice(0, 4)}... (created ${moment(token.created)
      .utc()
      .fromNow()
      .toString()})`
  });

  render() {
    const { isOpen, selectedOption } = this.state;

    const tokens = this.props.query.allTokens
      ? this.props.query.allTokens.edges.map(e => e.node)
      : [];

    const options = tokens.map(this.getOption);

    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <Button
            icon={"key"}
            loading={this.state.isCreating}
            rightIcon={"chevron-down"}
            onClick={this.toggleOpen}
            active={isOpen}
          >
            {selectedOption
              ? `Token: ${selectedOption.value.slice(0, 4)}...`
              : "Pick API Token"}
          </Button>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{
            // Option,
            DropdownIndicator,
            IndicatorSeparator: null
          }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable
          onCreateOption={this.create}
          isDisabled={this.state.isCreating}
          isLoading={this.state.isCreating}
          formatCreateLabel={() => "Generate New Token"}
          menuIsOpen
          onChange={this.onSelectChange}
          placeholder="Select Token..."
          styles={selectStyles}
          tabSelectsValue={false}
          value={selectedOption}
          options={options}
        />
      </Dropdown>
    );
  }
}

const Menu = props => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        right: 0,
        position: "absolute",
        zIndex: 2
      }}
      {...props}
    />
  );
};
const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1
    }}
    {...props}
  />
);

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);

export default compose(
  graphql(
      AllTokensQuery,
      {
        name: "query",
        options: (props) => ({variables: {type: props.type}})
      }),
  graphql(CreateToken,
      {
        name: "createToken",
        options: (props) => ({variables: {type: props.type}})
      })
)(TokenPicker);
