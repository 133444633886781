import React from 'react'

import {graphql, StaticQuery} from 'gatsby'
import {ApolloProvider} from 'react-apollo'
import {Router} from '@reach/router'

import Applications from './applications'
import Account from './account'
import Tokens from './tokens'
import DeveloperAPI from '../services/DeveloperAPIClient'
import NotFoundPage from '../pages/404'
import ApiGuideTemplate from '../templates/ApiGuidePage'

import Demo from './demos'

const apiGuideQuery = graphql`
query {
  allMarkdownRemark(
    filter:{fileAbsolutePath:{regex:"/content\\/api_guide/"}}) {
    edges {
      node {
        id
        frontmatter {
          title
        }        
        fields {
          slug
        }        
        htmlAst
        tableOfContents
        headings {
          value
          depth
        }       
      }
    }
  }
}`

class PrivateIndex extends React.Component {
  render() {
    return (
        <StaticQuery
            query={apiGuideQuery}
            render={data => {
             return  <ApolloProvider client={DeveloperAPI.createApolloClient()}>
                <Router>
                  <Applications path="/"/>
                  <Tokens path="/tokens/"/>
                  <Account path="/account/"/>
                  <Demo path="/demos/" />
                  <NotFoundPage default/>
                  {data.allMarkdownRemark.edges.map( ({node}) => (
                      <ApiGuideTemplate key={node.id} path={node.fields.slug} post={node}/>
                  ))}
                </Router>
              </ApolloProvider>
            }}/>
    )
  }
}

export default PrivateIndex