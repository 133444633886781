import React from 'react'

import { navigate } from 'gatsby'

import DeveloperAPI, {isBrowser} from '../services/DeveloperAPIClient'

export const PrivateRoute = ({component: Component, ...rest}) => {
  if (!isBrowser()) {
    return null
  }
  if (!DeveloperAPI.isAuthenticated()) {
    navigate("/login/", {replace: true})
    return null
  }

  return (<Component {...rest}/>)
}

export const PrivateSwitchRoute = ({logIn, loggedIn}) => {
  return DeveloperAPI.isAuthenticated() ? (loggedIn) : (logIn)
}
