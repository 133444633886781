import React from 'react'
import {Location} from '@reach/router'
import {graphql, navigate, StaticQuery} from 'gatsby'
import classNames from 'classnames'
import {
  Alignment,
  Classes,
  Divider,
  Icon,
  Menu as NavMenu,
  Navbar,
  NavbarGroup,
  Text
} from '@blueprintjs/core'
import {IconNames} from '@blueprintjs/icons'

import styled from 'styled-components'
import logo from '../media/bite_ai_logo.png'
import Layout from './Layout'

const NavbarStyled = styled(Navbar)`
  border: 0;
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 30px;
  margin-top: 20px;
`

const NavbarLogo = styled.img`
  height: 40px;
  margin: 0;
  margin-right: 1em;
`

const Menu = styled(NavMenu)`
  background-color: transparent;
`

const SidebarHeader = () => (
  <NavbarStyled>
    <NavbarGroup align={Alignment.LEFT}>
      <NavbarLogo src={logo} alt="Bite AI" />
    </NavbarGroup>
  </NavbarStyled>
)

/**
 * Replicate Blueprint MenuItem but with children shown below and indented
 * instead of as a dropdown popover
 */
const Item = ({
  text,
  icon,
  children,
  path,
  location,
  active,
  intent,
  expanded = true
}) => (
  <li className={Classes.MENU_SUBMENU}>
    <a
      className={classNames(Classes.MENU_ITEM, Classes.intentClass(intent), {
        [Classes.ACTIVE]: active || (location && location.pathname === path),
        [Classes.INTENT_PRIMARY]: active && Classes.intentClass(intent) == null
      })}
      href={path}
      onClick={goto(path)}>
      <Icon icon={icon} />
      <Text className={Classes.FILL}>{text}</Text>
    </a>
    {children &&
      expanded && <Menu style={{ paddingLeft: 10 }}>{children}</Menu>}
  </li>
)

const goto = path => e => {
  if (!(e.ctrlKey || e.metaKey || e.altKey)) {
    e.preventDefault()
    navigate(path)
  }
}

const APIGuideMenuItem = ({ location }) => (
  <StaticQuery
    query={graphql`
      {
        root: allMarkdownRemark(
          sort: { fields: [frontmatter___id] }
          filter: {
            fileAbsolutePath: { regex: "/content/api_guide/" }
            fields: { parentSlug: { eq: null } }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
              fields {
                slug
                parentSlug
                lastPathElem
              }
            }
          }
        }
        childrenGrouped: allMarkdownRemark(
          sort: { fields: [frontmatter___id] }
          filter: {
            fileAbsolutePath: { regex: "/content/api_guide/" }
            fields: { parentSlug: { ne: null } }
          }
        ) {
          group(field: fields___parentSlug) {
            fieldValue
            edges {
              node {
                id
                frontmatter {
                  title
                }
                fields {
                  slug
                  parentSlug
                  lastPathElem
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let childrenMap = {}
      data.childrenGrouped.group.forEach(group => {
        childrenMap[group.fieldValue] = group.edges
      })

      const renderRecursive = ({ node }) =>
        node && (
          <Item
            key={node.id}
            text={node.frontmatter.title}
            path={node.fields.slug}
            location={location}
            expanded={location.pathname.includes(node.fields.slug)}>
            {childrenMap[node.fields.lastPathElem] &&
              childrenMap[node.fields.lastPathElem].map(x =>
                renderRecursive({ node: x.node })
              )}
          </Item>
        )

      return (
        <Item
          icon={IconNames.MANUAL}
          text={'API Guide'}
          path={'/api_guide/'}
          location={location}
          expanded={location.pathname.includes('/api_guide/')}>
          {data.root.edges.map(renderRecursive)}
        </Item>
      )
    }}
  />
)

const Navigation = ({ location }) => (
  <>
    <Menu>
      <Item
        icon={IconNames.USER}
        text="Account"
        path={'/account/'}
        location={location}
      />
      <Item
        icon={IconNames.KEY}
        text="API Tokens"
        path="/tokens/"
        location={location}
      />
      <Item
        icon={IconNames.APPLICATIONS}
        text="Applications"
        path="/"
        location={location}
      />
    </Menu>

    <Divider />

    <Menu>
      <Item
        icon={IconNames.LAYERS}
        text="Demos"
        path="/demos/"
        location={location}
      />
    </Menu>

    <Divider />

    <Menu>
      <APIGuideMenuItem location={location} />
      <Menu.Item
        icon={IconNames.BOOK}
        text="API Reference"
        target="_blank"
        href={'https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/'}
      />
    </Menu>

    <Divider />

    <Menu>
      <Item
        icon={IconNames.LOG_OUT}
        text="Log Out"
        path="/logout/"
        location={location}
      />
    </Menu>
  </>
)

export class PrivateLayout extends React.Component {
  render() {
    const { children, ...rest } = this.props
    return (
      <Layout
        navigation={
          <>
            <SidebarHeader />
            <Location>
              {({ location }) => <Navigation location={location} />}
            </Location>
          </>
        }
        {...rest}>
        {children}
      </Layout>
    )
  }
}
