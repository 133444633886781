import React from 'react'

import { PrivateLayout } from '../components/PrivateLayout'

import rehypeReact from 'rehype-react'
import styled from 'styled-components'

const OrderedList = styled.ol`
  list-style: decimal;
  margin: 1rem;
`

const UnorderedList = styled.ul`
  list-style: disc;
  margin: 1rem;
`

const ListItem = styled.li`
  margin-left: 1rem;
  padding-bottom: 0.25rem;

  & :list-child {
    padding-bottom: 0;
  }
`

const H1 = styled.h1`
  font-family: Poppins, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 1.65rem;
  color: rgb(52, 52, 52);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const H2 = styled.h2`
  font-family: Poppins, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 1.5rem;
  color: rgb(52, 52, 52);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const H3 = styled.h3`
  font-family: Poppins, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 1.3rem;
  color: rgb(52, 52, 52);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const H4 = styled.h4`
  font-family: Poppins, Montserrat, 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 1.2rem;
  color: rgb(52, 52, 52);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`

const P = styled.p`
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.7rem;
  color: rgb(30, 30, 30);
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  max-width: 45rem;
  font-weight: 400;
`

const A = styled.a`
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.7rem;
  color: #106ba3;
  text-decoration: underline;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  max-width: 45rem;
  font-weight: 400;
`

const Div = styled.div`
  margin: 1rem 0;
`

const Br = styled.br`
  margin: 1rem 0 2rem 0;
`

const SecondaryTitle = styled.h2``

const TertiaryTitle = styled.h3``

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: A,
    p: P,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    div: Div,
    br: Br,
    ol: OrderedList,
    ul: UnorderedList,
    li: ListItem
  }
}).Compiler

class ApiGuideTemplate extends React.Component {
  render() {
    const post = this.props.post
    const title = post.frontmatter.title

    return (
      <PrivateLayout title={title}>
        <h1>{title}</h1>
        {/*<div dangerouslySetInnerHTML={{__html: post.tableOfContents}}/>*/}
        <div>{renderAst(post.htmlAst)}</div>
      </PrivateLayout>
    )
  }
}

export default ApiGuideTemplate
