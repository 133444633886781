import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'

const Page = styled.div`
  //display: flex;

  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(200px, calc(50% - 260px)) minmax(300px, 770px) 1fr;
  //flex-direction: column;
`


const Sidebar = styled.nav`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  flex: 0;
  width: 100%;

  background-color: #fafafa;
  border-right: 1px solid #EEE;
  //flex: 0 0 12em;
  //order: -1;
`

const SidebarContent = styled.div`
  position: sticky;
  min-width: 200px;
  width: 80%;
  max-width: 270px;
  top: 0;
  margin-left: auto;
`

const Content = styled.div`
  flex-direction: column;
  //justify-content: space-between;
  //max-width: 1000px;
  padding: 40px 30px 10px 30px;
  //margin: auto;
  min-height: 100vh;
  display: flex;
  //flex: 0;
  //overflow: auto;
  //padding: 0 1.5em 0 1.5em;
`

const MainContent = styled.div`
  flex: 1;
`

const Footer = styled.footer`
  text-align: center;
  padding: 10px;
  font-size:9pt;
  color: gray;
  &  a {
    color:gray;
  }
  & a:hover {
    color: #106ba3;
  }
  & > div {
    //margin-bottom: 0.25rem;
  }
  & ul {
    display: inline;
    //margin: 0;
    //margin-bottom: 100px;
  }
  & ul > li {
    display: inline;
  }

  & ul > :not(:first-child) {
    margin-left: 0.5rem;
  }
`


export const DefaultFooter = () => (
  <Footer>
    <div>
      <ul style={{display: 'inline', listStyle: 'circle'}}>
        <li><a href="https://bite.ai/privacy/">Privacy Policy</a></li>
        <li><a href="https://bite.ai/terms/">Terms</a></li>
      </ul>
    </div>
    <div>&copy; Bite AI 2018</div>
  </Footer>
)

export default class Layout extends React.Component {
  render() {
    const {title, children, navigation} = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Page>
            <Helmet
              htmlAttributes={{lang: 'en'}}
              meta={[{name: 'description'}]}
              title={title || data.site.siteMetadata.title}
            />
            {navigation && (
              <Sidebar>
                <SidebarContent>
                  {navigation}
                </SidebarContent>
              </Sidebar>
            )}
            <Content className={'content'}>
              <MainContent>
              {children}
              </MainContent>
              <DefaultFooter/>
            </Content>
            <div style={{flexGrow: 1}}/>
          </Page>
        )}
      />
    )
  }
}


