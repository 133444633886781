import React from 'react'

import { Router } from '@reach/router';

import NotFoundPage from './404'
import ForgotPassword from './forgot_password'
import Login from './login'
import Logout from './logout'
import ResetPassword from './reset_password'
import { PrivateRoute } from '../components/PrivateRoute'
import PrivateIndex from '../private_pages/index'

const NestedHome = () => {

}
class Index extends React.Component {
  render() {
    /**
     * Public routes need to be in both the router and in stand alone files. Otherwise, what
     * happens is that the standalone pages won't be accessible from the router or by directly
     * going to the page
     * -- Vinay 11/20/2018
     */
    return (
        <Router>
          <Login path="/login/"/>
          <Logout path="/logout/"/>
          <ForgotPassword path="/forgot_password/"/>
          <ResetPassword path="/reset_password/"/>
          <PrivateRoute path="/*" component={PrivateIndex}/>
          <NotFoundPage default/>
        </Router>
    )
  }
}

export default Index