import React from 'react'

import {Button, Dialog, Divider, H2, HTMLTable, Intent, Icon} from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import moment from 'moment'
import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import styled from 'styled-components'

import {PrivateLayout} from '../components/PrivateLayout'
import {Row} from '../components/Containers'
import {ApolloError} from '../components/ErrorsContainer'
import DeveloperAPI from '../services/DeveloperAPIClient'
import {ErrorFragment, TokenFragment} from '../services/DeveloperFragments'
import { AllTokensQuery, CreateToken} from '../services/DeveloperQueries'
import Spinner from '../components/Spinner'
import Secret from '../components/Secret'
import Refresh from '../components/Refresh'
import * as Classes from '@blueprintjs/core/lib/esm/common/classes'


function capCaseWord(word) {
  if (!word)
    return word

  return `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()} `
}

class Token extends React.Component {
  render() {
    const token = this.props.token
    return (
      <tr>
        <td style={{verticalAlign: 'middle'}}>
          <Secret value={token.key} textLike/>
        </td>
        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>{capCaseWord(token.type)}</td>
        <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
          <Refresh every={10000} created={moment(token.created).utc()}>
            {({created}) => <span>{created.fromNow()}</span>}
          </Refresh>
        </td>
        <td style={{verticalAlign: 'middle'}}>
          <Button
            onClick={this.props.onDelete}
            // text={'delete'}
            icon={'trash'}
            intent={Intent.DANGER}
            minimal
          />
        </td>
      </tr>
    )
  }
}

class TokenDeleteDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      deletionError: undefined,
      validationError: null
    }
    this.confirm = this.confirm.bind(this)
  }

  async confirm() {
    this.setState({
      loading: true,
      deletionError: null
    })
    try {
      let response = await this.props.mutate({
        variables: {key: this.props.token.key}
      })

      this.setState({loading: false})

      let validationErrors = DeveloperAPI.hasValidationErrors(
        response.data.deleteToken
      )
      if (validationErrors && validationErrors.key) {
        this.setState({validationError: validationErrors.key})
      } else {
        this.props.onDelete()
      }
    } catch (error) {
      this.setState({deletionError: error})
    }
  }

  render() {
    return (
      <Dialog
        icon="warning-sign"
        isOpen={this.props.isOpen}
        onClose={this.props.onCancel}
        title="Delete Token"
        canEscapeKeyClose={!this.props.loading}
        canOutsideClickClose={!this.props.loading}
        isCloseButtonShown={!this.props.loading}
        style={{paddingBottom: 10}}>
        <div className="pt-dialog-body" style={{padding: '1rem'}}>
          <p>
            Deleting the token will cause any clients using the key to no longer
            be to make authenticated calls. Once the token has been deleted it
            cannot be recovered.
          </p>
          <ApolloError
            graphQLError={this.state.deletionError}
            style={{margin: '0 auto', textAlign: 'center'}}
            showIcon={false}
          />
          {this.state.validationError ? (
            <div style={{textAlign: 'center'}}>
              <p>Deleting Token Error</p>
              <p>{this.state.validationError}</p>
            </div>
          ) : null}
        </div>
        <div className="pt-dialog-footer" style={{padding: '0 1rem 0 1rem'}}>
          <div className="pt-dialog-footer-actions">
            <Row justify="space-between" style={{padding: 0}}>
              <Button
                text="Cancel"
                onClick={this.props.onCancel}
                disabled={this.state.loading}
              />
              <Button
                intent={Intent.DANGER}
                onClick={this.confirm}
                text="Delete"
                disabled={this.state.loading}
              />
            </Row>
          </div>
        </div>
      </Dialog>
    )
  }
}

const TokenDeleteQuery = gql`
    mutation($key: String!) {
        deleteToken(key: $key) {
            ...ErrorTypeFragment
        }
    }
    ${ErrorFragment}
`

const TokenDeleteDialogMutable = graphql(TokenDeleteQuery)(TokenDeleteDialog)

const TokenContainer = styled.div`
  //& > * {
  //  margin: 0 2rem 2rem 0;
  //}
`
const PlusIcon = () =>
    <span style={{ marginLeft: ".5em", marginRight: ".75em"}}><Icon icon={IconNames.PLUS}/></span>

class Tokens extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteToken: null,
      isCreating: false,
      creationError: null
    }
    this.create = this.create.bind(this)
  }

  async create(tokenType) {
    this.setState({
      isCreating: true,
      creationError: null
    })
    try {
      await this.props.createToken({variables: {type: tokenType}})
      this.props.query.refetch()
    } catch (error) {
      this.setState({creationError: error})
    } finally {
      this.setState({isCreating: false})
    }
  }

  render() {
    return (
      <PrivateLayout title="Bite AI - Tokens">
        <H2>API Tokens</H2>
        <Divider/>

        <p className={`${Classes.RUNNING_TEXT} ${Classes.TEXT_LARGE}`}>
          API access tokens that are used to make requests to any API
          endpoint which require User Bearer Tokens or Admin Bearer Tokens. Admin Bearer Tokens
          should be kept private and never included in client side code.
        </p>

        <HTMLTable
          style={{
            boxShadow: '0px 10px 45px -16px rgba(0,0,0,0.1)',
            width: '100%',
            border: '1px solid #EEE',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            margin: '20px 0'
          }}
          striped>
          <thead>
          <tr>
            <th style={{paddingLeft: 20}}>Token</th>
            <th style={{textAlign: 'center'}}>Type</th>
            <th style={{textAlign: 'center'}}>Created</th>
            <th/>
          </tr>
          </thead>

          <tbody>
          {this.props.query.allTokens
            ? this.props.query.allTokens.edges.map((nodeContainer, index) => (
              <Token
                key={index}
                token={nodeContainer.node}
                onDelete={() =>
                  this.setState({deleteToken: nodeContainer.node})
                }
              />
            ))
            : null}
          </tbody>
        </HTMLTable>

        {this.props.query.loading ? <Spinner/> : null}
        <ApolloError
          graphQLError={this.props.query.error || this.state.creationError}
          style={{margin: '0 auto', textAlign: 'center'}}
        />
        <div>
          <Button onClick={() => {this.create('user')}} disabled={this.state.isCreating}>
            <PlusIcon/>
            <span>New User Token</span>
          </Button>
          <Button onClick={() => {this.create('admin')}} disabled={this.state.isCreating} style={{marginLeft: "2em"}}>
            <PlusIcon/>
            <span>New Admin Token</span>
          </Button>

        </div>
        <TokenDeleteDialogMutable
          token={this.state.deleteToken}
          isOpen={this.state.deleteToken}
          onCancel={() => this.setState({deleteToken: null})}
          onDelete={() => {
            this.setState({deleteToken: null})
            this.props.query.refetch()
          }}
        />
      </PrivateLayout>
    )
  }
}


export default compose(
  graphql(AllTokensQuery, {name: 'query'}),
  graphql(CreateToken, {name: 'createToken'})
)(Tokens)
