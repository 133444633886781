import React from 'react'

import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  Divider,
  EditableText,
  H2,
  H3,
  H6,
  Intent
} from '@blueprintjs/core'
import {ErrorMessage, Form, Formik} from 'formik'
import gql from 'graphql-tag'
import {graphql} from 'react-apollo'
import PropTypes from 'prop-types'
import Secret from '../components/Secret'
import {Row} from '../components/Containers'
import {ApolloError} from '../components/ErrorsContainer'
import {PrivateLayout} from '../components/PrivateLayout'
import DeveloperAPI from '../services/DeveloperAPIClient'
import {
  ApplicationNodeFragment,
  ErrorFragment
} from '../services/DeveloperFragments'
import Spinner from '../components/Spinner'

const containerStyle = {
  padding: '20px 30px',
  margin: '20px 10px',
  borderRadius: 5,
  boxShadow: '0px 0px 65px -26px rgba(0,0,0,0.27)',
  border: '1px solid #EFEFEF'
}

const Application = ({
  application,
  onChange,
  children,
  isEditing,
  onSubmitChanges,
  onCancelChanges,
  onDelete,
  validate,
  submitForm,
  mutate
}) => (
  <Formik
    initialValues={{ name: application.name || '' }}
    validate={validate}
    onSubmit={onSubmitChanges}>
    {({ dirty, isValid, values, setFieldValue, handleReset, isSubmitting }) => (
      <Form style={containerStyle}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <H3>
              <EditableText
                maxLength={40}
                isEditing={isEditing}
                placeholder={'New App Name'}
                value={values.name}
                onChange={name => setFieldValue('name', name)}
              />
            </H3>

            <H6 style={{ opacity: 0.6 }}>{`${application.userCount || 0} user${
              application.userCount === 1 ? '' : 's'
            }`}</H6>
          </div>
          <div>
            {isSubmitting ? (
              <Spinner delay={5} fadeInSpeed={10} size={Spinner.SIZE_SMALL} />
            ) : (
              application.name && (
                <Button icon={'trash'} minimal onClick={onDelete} />
              )
            )}
          </div>
        </div>

        {application.key && (
          <div style={{ marginTop: 30 }}>
            <Secret name="App Key" value={application.key} />
          </div>
        )}

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <div>
            <ErrorMessage name={'name'}/>
          </div>
          <div>
          {children}
          <ButtonGroup>
            {dirty &&
              isValid && (
                <Button onClick={submitForm} type={'submit'}>
                  {application.name ? 'Update' : 'Create'}
                </Button>
              )}
            {(dirty || !application.name) && (
              <Button
                onClick={() => {
                  handleReset()
                  if (onCancelChanges) onCancelChanges()
                }}>
                Cancel
              </Button>
            )}
          </ButtonGroup>
          </div>
        </div>
      </Form>
    )}
  </Formik>
)

const validate = applcation => {
  let errors = {}
  if (!applcation.name || applcation.name.trim() === '') {
    errors.name = 'Name can\'t be undefined'
  }
  return errors
}

const attemptUpdate = async (application, actions, mutate) => {
  actions.setSubmitting(true)
  try {
    const response = await mutate({
      variables: { name: application.name, applicationID: application.id }
    })
    if (response.data && response.data.createOrUpdateApplication) {
      let fieldErrors = DeveloperAPI.hasValidationErrors(
        response.data.createOrUpdateApplication
      )
      if (fieldErrors) {
        actions.setErrors(fieldErrors)
        actions.setSubmitting(false)
      }
    }
  } finally {
    actions.setSubmitting(false)
  }
}

class EditableApplicationComponent extends React.Component {
  state = {
    mutationError: undefined
  }

  submit = async (values, actions) => {
    actions.setSubmitting(true)
    this.setState({ mutationError: null })

    const variables = {
      name: values.name,
      applicationID: this.props.application.id
    }
    try {
      let response = await this.props.mutate({ variables: variables })
      let fieldErrors = DeveloperAPI.hasValidationErrors(
        response.data.createOrUpdateApplication
      )
      if (fieldErrors) {
        actions.setErrors(fieldErrors)
        actions.setSubmitting(false)
        return
      } else {
        this.props.onMutated()
      }
    } catch (error) {
      this.setState({ mutationError: error })
    } finally {
      actions.setSubmitting(false)
    }
  }

  render() {
    return (
      <Application
        application={this.props.application}
        validate={validate}
        onDelete={this.props.onDelete}
        onSubmitChanges={this.submit}
      />
    )
  }
}

EditableApplicationComponent.propTypes = {
  application: PropTypes.object,
  onMutated: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const UpdateApplicationQuery = gql`
  mutation($applicationID: ID!, $name: String!) {
    createOrUpdateApplication(input: { id: $applicationID, name: $name }) {
      ... on ErrorsType {
        ...ErrorTypeFragment
      }
      ... on ApplicationNode {
        ...ApplicationNodeFragment
      }
    }
  }
  ${ErrorFragment}
  ${ApplicationNodeFragment}
`
const EditableApplication = graphql(UpdateApplicationQuery)(
  EditableApplicationComponent
)

class NewApplicationComponent extends React.Component {
  state = {
    mutationError: null
  }

  submit = async (values, actions) => {
    this.setState({ mutationError: null })
    try {
      let response = await this.props.mutate({
        variables: { name: values.name }
      })
      if (response.data && response.data.createOrUpdateApplication) {
        let fieldErrors = DeveloperAPI.hasValidationErrors(
          response.data.createOrUpdateApplication
        )
        if (fieldErrors) {
          actions.setErrors(fieldErrors)
          actions.setSubmitting(false)
          return
        }
      }
      this.props.onCreate()
    } catch (error) {
      this.setState({ mutationError: error })
      // Don't set state after creating b/c the component will be removed
      actions.setSubmitting(false)
    }
  }

  render() {
    return (
      <Application
        validate={validate}
        application={{ name: '' }}
        onSubmitChanges={this.submit}
        onCancelChanges={this.props.onDelete}
        isEditing
      />
    )
  }
}

NewApplicationComponent.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

const CreateApplicationQuery = gql`
  mutation($name: String!) {
    createOrUpdateApplication(input: { name: $name }) {
      ... on ErrorsType {
        ...ErrorTypeFragment
      }
      ... on ApplicationNode {
        ...ApplicationNodeFragment
      }
    }
  }
  ${ErrorFragment}
  ${ApplicationNodeFragment}
`
const NewApplication = graphql(CreateApplicationQuery)(NewApplicationComponent)

class DeletionConfirmationDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      mutationError: null,
      idError: null
    }

    this.onConfirm = this.onConfirm.bind(this)
  }

  async onConfirm() {
    this.setState({
      loading: true,
      mutationError: null
    })

    try {
      let response = await this.props.mutate({
        variables: { applicationID: this.props.application.id }
      })
      let fieldErrors = DeveloperAPI.hasValidationErrors(
        response.data.deleteApplication
      )
      if (fieldErrors && fieldErrors.id) {
        this.setState({ idError: fieldErrors.id })
      } else {
        this.props.onDeleted()
      }
    } catch (errors) {
      this.setState({ mutationError: errors })
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <Dialog
        className="deletionConfirmation"
        icon="warning-sign"
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title="Delete Project"
        canEscapeKeyClose={!this.props.loading}
        canOutsideClickClose={!this.props.loading}
        isCloseButtonShown={!this.props.loading}>
        <div style={{ margin: '1rem', marginBottom: 0, paddingBottom: 0 }}>
          <div className="pt-dialog-body" style={{ marginBottom: '1rem' }}>
            Deleting the project will delete all of the users registered with
            this project key. Those users will no longer be able to access the
            API using their existing token. Only do this if you are sure.
          </div>
          <ApolloError
            graphQLError={this.state.mutationError}
            style={{ margin: '0 auto', textAlign: 'center' }}
            showIcon={false}
          />
          {this.state.idError ? (
            <div style={{ textAlign: 'center' }}>
              <p>Deleting Application Error</p>
              <p>{this.state.idError}</p>
            </div>
          ) : null}
          <div className="pt-dialog-footer">
            <div className="pt-dialog-footer-actions">
              <Row justify="space-between" style={{ padding: 0 }}>
                <Button
                  text="Cancel"
                  onClick={this.props.onClose}
                  disabled={this.state.loading}
                />
                <Button
                  intent={Intent.DANGER}
                  onClick={this.onConfirm}
                  text="Delete"
                  disabled={this.state.loading}
                />
              </Row>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const DeleteApplicationQuery = gql`
  mutation($applicationID: ID!) {
    deleteApplication(id: $applicationID) {
      ... on ErrorsType {
        ...ErrorTypeFragment
      }
    }
  }
  ${ErrorFragment}
`

const DeletionConfirmationDialogMutable = graphql(DeleteApplicationQuery)(
  DeletionConfirmationDialog
)

class Applications extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showCreate: false,
      applicationToDelete: null
    }
  }

  reloadApplications = () => this.props.data.refetch()

  render() {
    return (
      <PrivateLayout title="Bite AI - Applications">
        <H2>Applications</H2>
        <Divider />
        <p className={`${Classes.RUNNING_TEXT} ${Classes.TEXT_LARGE}`}>
          Applications allow you to manage users in you app and perform actions
          on their
          behalf.
        </p>

        <ApolloError
          graphQLError={this.props.data.error}
          style={{ margin: '0 auto', textAlign: 'center' }}
        />

        {this.props.data.loading ? <Spinner /> : null}

        {this.props.data.allApplications &&
          this.props.data.allApplications.edges.map((nodeContainer, index) => (
            <EditableApplication
              key={nodeContainer.node.name}
              application={nodeContainer.node}
              onMutated={this.reloadApplications}
              onDelete={() =>
                this.setState({ applicationToDelete: nodeContainer.node })
              }
            />
          ))}

        {this.props.data.allApplications ? (
          <>
            {this.state.showCreate ? (
              <NewApplication
                onDelete={() => {
                  this.setState({ showCreate: false })
                }}
                onCreate={() => {
                  this.setState({ showCreate: false })
                  this.props.data.refetch()
                }}
              />
            ) : (
              <div className="createApplication">
                <Button onClick={() => this.setState({ showCreate: true })}>
                  Create Application
                </Button>
              </div>
            )}

            <DeletionConfirmationDialogMutable
              application={this.state.applicationToDelete}
              isOpen={this.state.applicationToDelete}
              onClose={() => this.setState({ applicationToDelete: null })}
              onDeleted={() => {
                this.setState({ applicationToDelete: null })
                this.props.data.refetch()
              }}
            />
          </>
        ) : null}
      </PrivateLayout>
    )
  }
}

const GetApplicationsQuery = gql`
  {
    allApplications {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...ApplicationNodeFragment
        }
      }
    }
  }
  ${ApplicationNodeFragment}
`
export default graphql(GetApplicationsQuery)(Applications)
